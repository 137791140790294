<template>
  <div class="sms">
    <div class="page full">
      <!-- <div
        v-if="isMobile"
        style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <p class="">正在打开 “叮当智采”...</p>
   
        <a href="javascript:" @click="openWeapp()"> 打开小程序 </a>
      </div> -->
      <div v-if="isDesktop" style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        ">
        <p>请在手机打开网页链接</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getGeneratescheme } from "@/api/api_library/trial";
export default {
  name: "serviceOpen",
  data() {
    return {
      isMobile: false,
      isDesktop: false,
	  type: '', //类型
    };
  },
  created() {
	  // console.log(process.env.VUE_APP_BASE_API,'111')
	  let value = window.location.search || '?type=pending'
	  this.type = this.getParam(value,'type')
	  this.docReady();
  },
  mounted() { 
	  
  },
  methods: {
	  getParam(url, name) {
	      try {
	          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	          var r = url.split('?')[1].match(reg);
	          if(r != null) {
	            return r[2];
	          }
	          return "";//如果此处只写return;则返回的是undefined
	      } catch(e) {
	          return "";//如果此处只写return;则返回的是undefined
	      }
	  },
    docReady() {
      var ua = navigator.userAgent.toLowerCase();
      var isWXWork = ua.match(/wxwork/i) == "wxwork";
      var isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
        )
      ) {
        this.isMobile = true;
        this.openWeapp();
      } else {
        this.isDesktop = true;
        this.openWeapp();
      }

      // if (isWeixin) {
      //   this.openWeapp();
      // } else if (this.isDesktop) {
      //   // 在 pc 上则给提示引导到手机端打开
      //   debugger;
      //   this.showDesktop = true;
      // } else {
      //   this.openWeapp();
      // }
    },
    openWeapp(onBeforeJump) {
      // var c = window.c
      // const res = await c.callFunction({
      //   name: 'public',
      //   data: {
      //     action: 'getUrlScheme',
      //   },
      // })
      // console.warn(res)
      if (onBeforeJump) {
        onBeforeJump();
      }
	  let url = ''
	  if(this.type == 'pending'){ //待办页面
	  	url = '/pages/pending/index'
	  }
	  else if(this.type == 'index'){ //首页
	  	url = '/pages/index/index'
	  }
      getGeneratescheme(url)
        .then((res) => {
          location.href = res.result.openlink;
        })
        .catch(() => { });
      // location.href = "https://wxaurl.cn/aiow99lPHPm";
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}

.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.public-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-web-container p {
  position: absolute;
  top: 40%;
}

.public-web-container a {
  position: absolute;
  bottom: 40%;
}

.wechat-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wechat-web-container p {
  position: absolute;
  top: 40%;
}

.wechat-web-container wx-open-launch-weapp {
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-web-container p {
  position: absolute;
  top: 40%;
}
</style>

